<template>
  <div class="flex overflow-hidden relative">
    <div class="py-8 pr-7 w-full" :class="sideModal === true ? 'mr-72' : ''">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">Enquiry Log</h1>
        <button
          @click="addNew()"
          class="bg-primary py-4 px-7 rounded-xl shadow-lg flex items-center font-bold text-white"
        >
          <plus class="mr-5" />
          Add New
        </button>
      </div>
      <div class="" v-if="enquiries.length !== 0">
        <enquiry-table
          :allEnquiries="enquiries"
          :perPage="enquiryPerPage"
          :currentPage="currentPage"
          :total="totalEnquiry"
          @fetchPage="fetchAllEnquiries"
          @edit="editEnquiry"
          @delete="deleteThisEnquiry"
          @view="viewEnquiry"
        />
      </div>
      <div class="h-full w-full flex items-center justify-center" v-else>
        <Spinner />
      </div>
    </div>
    <edit-addmission
      v-if="sideModal"
      :enquiry="enquiryDetail"
      @close="sideModal = false"
      @edit="editEnquiry"
    />
    <add-enquiry
      v-if="overlay"
      :newEnquiry="enquiry"
      @close="
        overlay = false;
        enquiry = {};
      "
      @created="fetchAllEnquiries"
    />
  </div>
</template>

<script>
import { getEnquiries, deleteEnquiry } from "@/services/enquiry";

export default {
  name: "EnquiryIndex",
  components: {
    plus: () => import("@/assets/icons/PlusIcon.vue"),
    EnquiryTable: () => import("@/components/Enquiry/EnquiryTable.vue"),
    AddEnquiry: () => import("@/components/Enquiry/AddEnquiryModal.vue"),
    EditAddmission: () =>
      import("@/components/Addmission/EditAddmissionModal.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
  },
  data() {
    return {
      sideModal: false,
      sideModalData: {},
      overlay: false,
      enquiries: [],
      totalEnquiry: 0,
      enquiryPerPage: 0,
      currentPage: 0,
      enquiryDetail: {},
      enquiry: {},
      submitting: false,
    };
  },
  methods: {
    addNew() {
      this.overlay = true;
    },
    closeNewCorpse() {
      this.sideModal = false;
      this.overlay = false;
    },
    viewEnquiry(enquiry) {
      this.sideModal = true;
      this.enquiryDetail = enquiry;
    },
    editEnquiry(enquiry) {
      this.overlay = true;
      this.enquiry = enquiry;
    },
    async deleteThisEnquiry(enquiryID) {
      this.$toast.info("Deleting Enquiry");
      try {
        const response = await deleteEnquiry(enquiryID);
        console.log(response);
        this.$toast.success("Enquiry deleted");
        this.fetchAllEnquiries();
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response.data.message || error);
      }
    },
    async fetchAllEnquiries(page = 10) {
      this.sideModal = false;
      this.overlay = false;
      try {
        const response = await getEnquiries(page);
        this.enquiries = response.data.enquiry.data;
        this.enquiryPerPage = response.data.enquiry.per_page;
        this.totalEnquiry = response.data.enquiry.total;
        this.currentPage = response.data.enquiry.current_page;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchAllEnquiries();
  },
};
</script>
